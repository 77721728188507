.parallax-is-title {
  /* The image used */
  background-image: url("assets/images/games/infinite-silence/big-title.png");

  /* Set a specific height */
  min-height: 1000px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.parallax-is-2 {
  /* The image used */
  background-image: url("assets/images/games/infinite-silence/is2.png");

  /* Set a specific height */
  min-height: 1000px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.parallax-is-11 {
  /* The image used */
  background-image: url("assets/images/games/infinite-silence/is11.png");

  /* Set a specific height */
  min-height: 1000px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.parallax-is-6 {
  /* The image used */
  background-image: url("assets/images/games/infinite-silence/is6.png");

  /* Set a specific height */
  min-height: 1000px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.parallax-ph-2 {
  /* The image used */
  background-image: url("assets/images/games/phreaker/phreaker2.png");

  /* Set a specific height */
  min-height: 1000px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.parallax-ph-3 {
  /* The image used */
  background-image: url("assets/images/games/phreaker/phreaker3.png");

  /* Set a specific height */
  min-height: 1000px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.parallax-ph-5 {
  /* The image used */
  background-image: url("assets/images/games/phreaker/phreaker5.png");

  /* Set a specific height */
  min-height: 1000px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.parallax-ph-8 {
  /* The image used */
  background-image: url("assets/images/games/phreaker/phreaker8.png");

  /* Set a specific height */
  min-height: 1000px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.parallax-ph-title {
  /* The image used */
  background-image: url("assets/images/games/phreaker/phTitle.png");

  /* Set a specific height */
  min-height: 1000px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.parallax-oh-1 {
  /* The image used */
  background-image: url("assets/images/games/clock-out/oh1.png");

  /* Set a specific height */
  min-height: 1000px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.parallax-ice {
  /* The image used */
  background-image: url("assets/art/ice-wide.png");

  /* Set a specific height */
  min-height: 1000px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.parallax-vid {
  /* The image used */
  background-image: url("assets/art/loop-centerlight.gif");

  /* Set a specific height */
  min-height: 1000px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
