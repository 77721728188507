.App {
  text-align: left;
  background-color: #f72585;
  min-height: 100vh;
  font-family: "Anton", Impact, sans-serif;
  color: #4cc9f0;
}

.title {
  font-weight: bold;
  font-size: 140px;
  margin-top: 150px;
  text-transform: uppercase;
}

.title-mobile {
  font-weight: bold;
  font-size: 110px;
  margin-top: 100px;
  margin-bottom: 35px;
  text-transform: uppercase;
}

.email {
  font-size: 60px;
  background-color: #4cc9f0;
  color: #f72585;
}

.email-mobile {
  font-size: 38px;
  background-color: #4cc9f0;
  color: #f72585;
}

.email-link {
  text-decoration: none;
  background-color: #4cc9f0;
  color: #f72585;
}

.email-link:hover {
  color: #4cc9f0;
  background-color: #00ffc5;
}

.highlight-info {
  cursor: default;
  font-size: 32px;
  background-color: #4cc9f0;
  color: #f72585;
}

.highlight-info:hover {
  color: #4cc9f0;
  /* color: #4cc9f0; */
  background-color: #00ffc5;
}

.spacer {
  margin-top: 100px;
  margin-bottom: 100px;
  background: red;
}

.fade {
  height: 200px;
  /* background: rgb(247, 37, 133); */
  /* background: linear-gradient(
    264deg,
    rgba(247, 37, 133, 1) 0%,
    rgba(37, 105, 247, 1) 100%
  ); */
}

.spacer-mobile {
}

/* GOOD FONTS: https://design.tutsplus.com/articles/17-fonts-similar-to-impact-and-impact-font-history--cms-36628 */
