.external-link {
  color: #deffdb;
}

.external-link:hover {
  background-color: #f0ff00;
  color: #0f1117;
}

.spacer {
  height: 100px;
}

.modified-image {
  filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg)
    saturate(400%) contrast(0.8);
}

.modified-image:hover {
  transform: scale(2);
  z-index: 999999999999 !important;
  filter: contrast(1);
}

.mouse-highlight {
  cursor: default;
}

.mouse-highlight:hover {
  color: #00ffc5;
  /* color: #3a0ca3; */
  cursor: default;
}
